import React from 'react'
import gelNailExtensionNearMeImg from "./../../../Images/NearMe/Gel Nails Extension.png";

const GelNailExtensionNearMeImg = () => {
  return (
    <div className="haircutImg">
    <img src={gelNailExtensionNearMeImg} alt="gelNailExtensionNearMeImg" />
  </div>
  )
}

export default GelNailExtensionNearMeImg
