import React from 'react'

const AcademyWhyChooseHead = () => {
  return (
    <div className="academyWhyChoose-headContainer">
      <h2>Why Choose SCENTLifestyle Academy</h2>
    </div>
  )
}

export default AcademyWhyChooseHead
