import React from 'react'
import pedicureNearMeImg from "./../../../Images/NearMe/Pedicure Near Me.png";

const PedicureNearMeImg = () => {
  return (
    <div className="haircutImg">
    <img src={pedicureNearMeImg} alt="pedicureNearMeImg" />
  </div>
  )
}

export default PedicureNearMeImg
