import React from "react";
import QAPageCard from "../QAPageCard";

const QuestionAnswer1 = () => {
  return (
    <div className="questionAnswer-container">
      <QAPageCard
        name="John Doe"
        questions="How do native speakers tell I’m foreign based on my English alone?"
        para="I’m a 19-year-old student from Malaysia. I’ve been introduced to the 
             language at a very young age and I’m capable of conducting any type 
             of conversation. However, some of my English-speaking friends on the 
             internet didn’t take too long to figure I’m not a native speaker. Why is that?"
      />{" "}
      <div className="questionAnswer-container">
        
      </div>
    </div>
  );
};

export default QuestionAnswer1;
