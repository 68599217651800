import React from "react";
import BodyWaxingJpImg from "./../../../Images/SahakarNagar/Waxing, Threding & D-Tan.png";

const BodyWaxingInLavelleImg = () => {
  return (
    <div className="hairSalonInLavelleImg">
      <img src={BodyWaxingJpImg} alt="bodyWaxingInLavelleImg" loading="lazy" />
    </div>
  );
};

export default BodyWaxingInLavelleImg;
