import React from "react";
import "./AcademyModel.css";

const AcademyModelHead = () => {
  return (
    <div className="academyModelHead-container">
      <h2>Experience a Glamorous Makeover at SCENT Academy!</h2>
    </div>
  );
};

export default AcademyModelHead;
