import React from 'react'
import BodyWaxingImg from "./../../../Images/SahakarNagar/Waxing, Threding & D-Tan.png";

const BrazilWaxingServiceImg = () => {
  return (
    <div className="HairSalonJpImg">
    <img src={BodyWaxingImg} alt="BodyWaxingImg" />
  </div>
  )
}

export default BrazilWaxingServiceImg
