import React from 'react'
import nailArtNearMeImg from "./../../../Images/NearMe/Nail Art Near Me.png";

const NailArtNearMeImg = () => {
  return (
    <div className="haircutImg">
    <img src={nailArtNearMeImg} alt="nailArtNearMeImg" />
  </div>
  )
}

export default NailArtNearMeImg
