import React from 'react'
import spaJpImg from "./../../../Images/SahakarNagar/Spa.png";

const SpaInLavelleImg = () => {
  return (
    <div className="hairSalonInLavelleImg">
      <img src={spaJpImg} alt="spaInLavelleImg" loading='lazy' />
    </div>
  )
}

export default SpaInLavelleImg
