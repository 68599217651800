import React from "react";
import microBladingImg from "./../../../Images/SahakarNagar/Eyeborw Microblading.png";

const MicroBladingInJakkurImg = () => {
  return (
    <div className="hairSalonInJakkurImg">
      <img src={microBladingImg} alt="microBladingJakkurImg" />
    </div>
  );
};

export default MicroBladingInJakkurImg;
