import React from "react";
import pedicuristNearMeImg from "./../../../Images/NearMe/Pedicurist Near Me.png";

const PedicuristNearMeImg = () => {
  return (
    <div className="haircutImg">
      <img src={pedicuristNearMeImg} alt="pedicuristNearMeImg" />
    </div>
  );
};

export default PedicuristNearMeImg;
